
<template>
    <el-card style="width: 40%;">
        <el-form ref="form" :model="form" label-width="80px" style="margin: 10px;">

            <el-input placeholder="请选择号码" v-model="call_num" clearable style="width: 232px; margin: 10px"></el-input>

            <el-row style="margin: 10px">
                <el-button size="medium" style="height: 70px ; width: 70px" @click="call_num=call_num+'1'">1</el-button>
                <el-button size="medium" style="height: 70px ; width: 70px" @click="call_num=call_num+'2'">2</el-button>
                <el-button size="medium" style="height: 70px ; width: 70px" @click="call_num=call_num+'3'">3</el-button>
            </el-row>
            <el-row style="margin: 10px">
                <el-button size="medium" style="height: 70px ; width: 70px" @click="call_num=call_num+'4'">4</el-button>
                <el-button size="medium" style="height: 70px ; width: 70px" @click="call_num=call_num+'5'">5</el-button>
                <el-button size="medium" style="height: 70px ; width: 70px" @click="call_num=call_num+'6'">6</el-button>
            </el-row>
            <el-row style="margin: 10px">
                <el-button size="medium" style="height: 70px ; width: 70px" @click="call_num=call_num+'7'">7</el-button>
                <el-button size="medium" style="height: 70px ; width: 70px" @click="call_num=call_num+'8'">8</el-button>
                <el-button size="medium" style="height: 70px ; width: 70px" @click="call_num=call_num+'9'">9</el-button>
            </el-row>
            <el-row style="margin: 10px">
                <el-button size="medium" style="height: 70px ; width: 70px" @click="call_num=call_num+'*'">*</el-button>
                <el-button size="medium" style="height: 70px ; width: 70px" @click="call_num=call_num+'0'">0</el-button>
                <el-button size="medium" style="height: 70px ; width: 70px" @click="call_num=call_num+'#'">#</el-button>
            </el-row>
            <el-button type="success" round style="height: 40px ; width: 232px; margin: 10px" @click="call">拨号</el-button>


        </el-form>
    </el-card>

</template>

<script>


    export default {
        name: "dashboard",
        data(){
            return {
                call_num:'',

            }
        },
        methods:{
            call(){
                //console.log(this.call_num)
            },


        },
        components:{

        }
    }
</script>

<style scoped>

</style>
